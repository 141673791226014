@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap);
/* Global */

* {
  border: none !important;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Helvetica Neue, Arial, Noto Sans,  Apple Color Emoji,
  Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  
  
}
#root{
  background: linear-gradient(38deg, rgba(23,14,95,1) 7%, rgba(30,30,73,1) 100%) !important;
}

/* Chats Page */


input{
  font-size:Roboto ;
}
.chatengine{
  font-family:Avenir ;
}
.testbro{
  background-color: black;
}

.chats-page {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  /* background-color: black ; */
  background: linear-gradient(38deg, rgba(23,14,95,1) 7%, rgba(30,30,73,1) 100%) !important;
  font-family: Avenir;
}

.nav-bar {
  width: 100%;
  height: 66px;
  background: linear-gradient(38deg, rgba(23,14,95,1) 7%, rgba(30,30,73,1) 100%) !important;
  /* background-color: #00FFC6; */
}

.logo-tab {
  position: absolute;
  left: 22px;
  top: 12px;
  font-size: 32px;
  font-weight: 700;
  color: white;
}

.logout-tab {
  position: absolute;
  top: 22px;
  right: 22px;
  color: white;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Login Page */

#login-page {
  background: linear-gradient(38deg, rgba(23,14,95,1) 0%, rgba(29,29,226,1) 100%) !important;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

#login-card {
  position: relative;
  top: calc(50vh - 144px);
  left: calc(50vw - 210px);
  padding-top: 36px;
  padding-bottom: 66px;
  width: 420px;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 22px;
}

.login-button {
  cursor: pointer;
  color: white;
  padding: 12px;
  border-radius: 8px;
  display: inline-block;
}

.facebook {
  background-color: #3b5998;
}

.google {
  background-color: #4285f4;
  color: white;
}

#loading-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgb(255, 255, 255, 0.22);
}

#loading-icon {
  font-size: 66px;
  position: relative;
  left: calc(50vw - 33px);
  top: calc(50vh - 22px);
}
.ce-chat-list {
  /* color: white; */
  /* background-color: #247881 !important; */
  background: linear-gradient(38deg, rgba(23,14,95,1) 7%, rgba(30,30,73,1) 100%) !important;
}
.ce-chats-container {
  /* color: white; */
  background: linear-gradient(38deg, rgba(23,14,95,1) 7%, rgba(30,30,73,1) 100%) !important;
  /* border: grey solid 1px !important; */
  border: none !important;
}
.ce-chat-card{
  color: white !important;
  border-radius: 0% !important;
}
.ce-chat-feed-container{
  background: linear-gradient(38deg, rgba(23,14,95,1) 7%, rgba(30,30,73,1) 100%) !important;
  border: none;
  /* background-color: #247881 !important; */
}
.ce-settings{
  background: linear-gradient(38deg, rgba(23,14,95,1) 7%, rgba(30,30,73,1) 100%) !important;
  border: none !important;
  box-shadow: 15px -2px 50px -26px rgba(255,255,255,0.3) inset;
  -webkit-box-shadow: 15px -2px 50px -26px rgba(255,255,255,0.3) inset;
  -moz-box-shadow: 15px -2px 50px -26px rgba(255,255,255,0.3) inset;
  /* border-radius: 10px; */
  /* background-color: #247881 !important; */
}
.ce-person-title-container{
  color: white !important;
}
.ce-person-container{
  color: white !important;
}
.ce-textarea-input{
  font-family: 'Dosis', sans-serif !important;
  color: #092327 !important;
}
.ce-chat-form-container{
  background: linear-gradient(38deg, rgba(23,14,95,1) 7%, rgba(30,30,73,1) 100%) !important;
  /* background-color: red !important; */
  color: white;
  /* box-shadow: 15px -2px 50px -26px rgba(255,255,255,0.75) inset;
  -webkit-box-shadow: 15px -2px 50px -26px rgba(255,255,255,0.75) inset;
  -moz-box-shadow: 15px -2px 50px -26px rgba(255,255,255,0.75) inset; */
}
.ce-chat-title{
  background: linear-gradient(38deg, rgba(23,14,95,1) 7%, rgba(30,30,73,1) 100%) !important;
  
  color: white !important;
  box-shadow: 15px -2px 50px -26px rgba(255,255,255,0.75) inset;
  -webkit-box-shadow: 15px -2px 50px -26px rgba(255,255,255,0.2) inset;
  -moz-box-shadow: 15px -2px 50px -26px rgba(255,255,255,0.2) inset;
}
.ce-chat-card:hover {
  background-color: rgba(255,255,255,0.05) !important;
}
.ce-active-chat-card {
  background-color: rgba(176, 92, 255, 0.3) !important;
}
.ce-chat-title-text:active  {
  color: rgb(255, 0, 0);
}
.ce-chat-title-text  {
  color: white;
}
.ce-chat-subtitle-text  {
  color: white;
}
.ce-input ,.ce-text-input{
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.ce-settings-containers{
  background-color: black !important;
}
.ce-person-title-container{
  margin-top: 1vh;
}
.ce-person-title-container , .ce-photo-section ,#ce-options-drop-down {
  color: white !important;
  /* background-color: red; */
  border-radius: 40px !important;
}
.ce-photo-section , .ce-photo-title-container{
  
  border-radius: 40px !important;
}
.ce-section-title:hover{
  /* background-color: red; */
  color: black;
  /* background-color: rgba(255, 255, 255, 0.3) !important; */
}
#ce-options-drop-down{
  color: rgb(255, 255, 255) !important;
}
#ce-options-drop-down :hover{
  color: black;
}.ce-text-input{
  background-color: rgba(208, 166, 248, 0.3) !important;
  color: white;
  font-family: Avenir,Segoe UI !important;
}
.ce-message-date-text, .ce-their-message-sender{
  color: white !important;
}
